import React, { useEffect, useState } from 'react'
import { Script } from 'gatsby'
import Wrapper from '../components/wrapper'
import PageNav from '../components/pageComponents/common/pageNav'
import JobSearchComponent from '../components/pageComponents/jobSearchComponent'
import cookies from 'react-cookies'
import { auth0Roles, strapiURL, GOOGLE_API_KEY } from '../config'
import axios from 'axios'

const JobSearchPage = ({ location }) => {
  const [googleAPILoaded, setGoogleAPILoaded] = useState(false)
  const [employerProfile, setEmployerProfile] = useState(null)
  let user = cookies.load('user')
  let userRole = cookies.load('userRole')

  useEffect(() => {
    if (user) {
      if (
        (user && userRole === auth0Roles?.Employer?.id) ||
        userRole === auth0Roles.NamedUser.id
      ) {
        axios
          .post(strapiURL + '/employers/profile', {
            username: user?.username,
          })
          .then(res => {
            setEmployerProfile(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }, [])

  const Children = props => {
    return (
      <>
        <PageNav navItem="Search Jobs" />
        <JobSearchComponent
          {...props}
          user={user}
          location={props.location}
          isSearch={true}
          employerProfile={employerProfile}
          role={userRole}
          openSignupModal={props.openSignupModal}
          openLoginModal={props.openLoginModal}
        />
      </>
    )
  }

  return (
    <Wrapper
      location={location}
      title="Search Medical Sales Jobs | MedReps"
      description="Start your search today and explore a wide range of medical sales jobs with MedReps. Find the perfect match for your skills and experience and begin your journey towards a fulfilling career in medical sales."
    >
      {googleAPILoaded ? (
        <Children />
      ) : (
        <Script
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
          onLoad={() => setGoogleAPILoaded(true)}
        />
      )}
    </Wrapper>
  )
}
export default JobSearchPage
